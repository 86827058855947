<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <TheUserDetail @addBread="addBread"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  // name: "Codes",
  components: {
    TheUserDetail: () =>
      import(
        "@/components/dashboardComponents/TheUserDetail"
      )
  },
  data: () => ({
    create: false,
    page: {
      title: "Códigos"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/dashboard/index/"
      },
      {
        text: "Usuarios",
        disabled: false,
        href: "/dashboard/users/"
      }
    ]
  }),
  computed: {
    ...mapState('usuarios', ['userDetail'])
  },
  methods: {
    addBread () {
      const newBreadCrumb = {
        text: `${this.userDetail.username}`,
        disabled: true
      }
      this.breadcrumbs.push(newBreadCrumb)
    }
  }
};
</script>
